@import "__elements";
@import "__common";
@import "__config";
/* ==== header ==== */
.header{
	background: white;
}

#header2-6{
	background-image: url(../img/etc/bg2.jpg);
}