@import "__elements";
@import "__common";
@import "__config";
/* ==== plugin ==== */

// particles-js
#particles-js{
	position: fixed;  width: 100%;  height: 100%;
}


// mobirise

.mbr-overlay{
	opacity: 0.5; background-color: rgb(239, 239, 239);
}