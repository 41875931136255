@import "__elements";
@import "__common";
@import "__config";
/* ==== footer ==== */
.footer{
	display: block;


	a{
		.hoverEffect();
		&:hover{
			color:#fff;
		}
	}
}