@import 'normalize.min.css';
//@import '//maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css';
//@import 'webfonts/font-awesome/css/font-awesome.min.css';
//@import '../js/lib/mmenu/jquery.mmenu.all.css';
@import 'https://fonts.googleapis.com/css?family=Roboto:700,400&amp;subset=cyrillic,latin,greek,vietnamese';
@import '../lib-framework/bootstrap/css/bootstrap.min.css';
@import '../lib-framework/animate.css/animate.min.css';
@import '../lib-framework/mobirise/css/style.css';
@import '../lib-framework/mobirise/css/mbr-additional.css';


@import "__elements";
@import "__common";
@import "__config";
@import "__layout";
@import "__layer";

@page { size: A4; }
html{
	font-size: 62.5%; //* 10(base font size) / 16(Browser Default font size) = 0.625em * 100 = 62.5% */
}
body{
	font-size: 1.4em;
	scroll-behavior: smooth;
	//  Supported in Chrome by enabling the "Smooth Scrolling" or "Enable experimental web platform features" flag in chrome://flags.
	// Browser compatibility : Desktop Chrome, Firefox (Gecko) 36over and Firefox Mobile (Gecko) 36over.
	// https://developer.mozilla.org/ja/docs/Web/CSS/scroll-behavior
	font-family: Roboto,'Droid Sans','Lucida Grande',YuGothic,'游ゴシック','Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3',Meiryo,'メイリオ',sans-serif;
}

@import "_01_header";
@import "_02_footer";
@import "_03_generic";
@import "_04_plugin";
@import "_05_side";
@import "_06_btn";
@import "_07_block";
@import "_08_title";
@import "_09_text";
@import "_home";
/*  ss */