@import "__elements";
@import "__common";
@import "__config";
/* ==== home ==== */
#particles-js{
	z-index: 1;
}
#features1-4{
	background-color: rgb(255, 255, 255);
}
#contacts1-5{
	background-color: rgb(60, 60, 60);
}
#features1-4{
	.mbr-article{
		span{
			display: inline-block;
			font-size: 16px;
		}
	}
	.waypoints{
		//.transition-duration(1s);
		.transition(@duration:0.8s, @ease:ease-out);
		&.fadeOutUp{
			.opacity(0.2);
			.transform(translate(0,20%));
		}
		&.fadeInUp{
			.opacity(1);
			.transform(translate(0,0));
		}
	}
}

@import "_home_s1";
@import "_home_s2";
@import "_home_s3";
@import "_home_s4";
@import "_home_s5";
@import "_home_s6";
