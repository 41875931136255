@import "__elements";
@import "__common";
@import "__config";
/* ==== generic ==== */

.engine{
	display: none!important;
}

a{
	.hoverEffect();
	&:hover{
		.opacity(0.6);
	}
}
